import React, { useState,useCallback,useContext } from "react"
import ReactDOM from "react-dom"
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import DataStore from './stores/data'
import { observer } from 'mobx-react-lite'

import { Route, Switch, BrowserRouter } from 'react-router-dom'

import Uploader from './Uploader'
import MakeNewKiste from './MakeNewKiste'
import Kiste from './Kiste'
import StoryViewer from './StoryViewer'

import {
 CssBaseline
} from '@material-ui/core'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'


const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Quicksand',
      'sans-serif',
    ].join(','),
  },});


const App = observer(() => {
 const { view,views } = useContext(DataStore)

  return (
   <ThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
     <Switch>
      <Route exact path='/' component={Uploader}/>
      <Route path='/new' component={MakeNewKiste}/>
      <Route path='/box' component={Kiste}/>
      <Route path='/storyviewer' component={StoryViewer} />
     </Switch>
    </BrowserRouter>

   </ThemeProvider>
  )

})
//
// {view === views.START && <Uploader />}
// {view === views.MAKE_NEW_KISTE && <MakeNewKiste />}
// {view === views.KISTE && <Kiste />}

export default App
